import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "./checkoutForm";
import { Pen, PlusIcon } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Store, Truck } from "lucide-react";
import { auth } from "../../firebase";
import axios from "axios";
import { ChevronRight } from "lucide-react";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  "pk_live_51O8m2FC4BUAyrHhVCDjVlVeq8ALviT4wyk2OuOtOzgeMY8SHPj9uUDN4tujwKAD06rk4rZH1ixJzdYjJRnrDZN4j00P0ARa2Ec"
);
const calculateTimeLeft = (targetDate) => {
  const now = new Date().getTime();
  const distance = targetDate - now;

  if (distance < 0) {
    return "Expired";
  }

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

const generateTimeSlots = (open, close) => {
  if (!open || !close) {
    console.error("Invalid open or close time provided");
    return [];
  }

  const result = [];
  const currentDate = new Date();

  // Define start time 50 minutes from now
  let startTime = new Date(currentDate.getTime() + 10 * 60000);

  try {
    const [openHours, openMinutes, openPeriod] = open
      .match(/(\d+):(\d+) (\w+)/)
      .slice(1);
    const [closeHours, closeMinutes, closePeriod] = close
      .match(/(\d+):(\d+) (\w+)/)
      .slice(1);

    // Calculate opening time from provided open string
    const openingTime = new Date(currentDate);
    openingTime.setHours(
      openPeriod === "PM" && openHours !== "12"
        ? parseInt(openHours) + 12
        : parseInt(openHours),
      parseInt(openMinutes),
      0,
      0
    );

    // Adjust start time to the maximum of the computed start time or the opening time
    startTime = new Date(Math.max(startTime, openingTime));

    // Calculate closing time from provided close string
    const closingTime = new Date(currentDate);
    closingTime.setHours(
      closePeriod === "PM" && closeHours !== "12"
        ? parseInt(closeHours) + 12
        : parseInt(closeHours),
      parseInt(closeMinutes),
      0,
      0
    );

    // Subtract 10 minutes to ensure closing time ends 10 minutes before actual closing
    closingTime.setMinutes(closingTime.getMinutes() - 10);

    // Round start time to the next 15-minute increment
    startTime.setMinutes(Math.ceil(startTime.getMinutes() / 15) * 15);

    // Generate time slots
    while (startTime < closingTime) {
      result.push(
        startTime.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      );
      startTime.setMinutes(startTime.getMinutes() + 15);
    }
  } catch (error) {
    console.error("Error generating time slots:", error);
  }

  console.log("Generated timeslots:", result);
  return result;
};

const PlaceOrder = () => {
  const [showPaymentSection, setShowPaymentSection] = useState(false);

  const navigate = useNavigate();
  const [needsNewPaymentIntent, setNeedsNewPaymentIntent] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [products, setProducts] = useState([]);
  const [isDelivery, setIsDelivery] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState("pickup"); // Default to "pickup"
  const [notes, setNotes] = useState({});
  const [currentProduct, setCurrentProduct] = useState(null);
  const [tempNote, setTempNote] = useState("");
  const [storeNames, setStoreNames] = useState({});
  const [storePickupTimes, setStorePickupTimes] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [fee, setFee] = useState(1);
  const [originalCost, setOriginalCost] = useState(0);
  const [selectedStore, setSelectedStore] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("6PM"); // Default to "Standard"
  const [deliveryFee, setDeliveryFee] = useState(5);
  const [pickupTime, setPickupTime] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [taxAmount, setTaxAmount] = useState(0);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [openAccordion, setOpenAccordion] = useState("personal-info");
  const [currentUser, setCurrentUser] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [finalTotal, setFinalTotal] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isNFormValid, setIsNFormValid] = useState(false);
  const [isPaymentFormValid, setIsPaymentFormValid] = useState(false);
  const [isStoreOpen, setIsStoreOpen] = useState(false);
  const [isNewCardValid, setIsNewCardValid] = useState(false);
  const targetDate = new Date("October 6, 2024 23:59:59").getTime();
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const updateCountdown = () => {
      setTimeLeft(calculateTimeLeft(targetDate));
    };

    // Update countdown every second
    const interval = setInterval(updateCountdown, 1000);

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, [targetDate]);
  const handleContinue = async () => {
    if (currentUser && selectedPaymentMethod !== "new") {
      // The user is logged in and using a saved payment method, so don't create a new payment intent here
      setShowPaymentSection(true);
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/orders/create-or-update-payment-intent`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            storeId: selectedStore,
            amount: totalCost,
            fees: fee + deliveryFee,
            orderType: isDelivery ? "delivery" : "pickup",
            customerId: currentUser ? currentUser.uid : null,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create payment intent");
      }

      const { clientSecret, paymentIntentId } = await response.json();
      setClientSecret(clientSecret);
      setPaymentIntentId(paymentIntentId);
      setShowPaymentSection(true);
    } catch (error) {
      console.error("Error creating payment intent:", error);
      alert("Failed to proceed to payment. Please try again.");
    }
  };

  const checkFormValidity = () => {
    const isPersonalInfoValid = firstName && lastName && email && phone;
    const isDeliveryPickupValid = isDelivery
      ? address && city && state && zipcode && deliveryMethod && isStoreOpen
      : pickupTime && isStoreOpen;
    const isPaymentValid = currentUser
      ? selectedPaymentMethod !== ""
        ? selectedPaymentMethod !== "new" || isNewCardValid
        : false
      : isPaymentFormValid;

    setIsFormValid(
      isPersonalInfoValid && isDeliveryPickupValid && isPaymentValid
    );
  };
  const checkNFormValidity = () => {
    const isPersonalInfoValid = firstName && lastName && email && phone;
    const isDeliveryPickupValid = isDelivery
      ? address && city && state && zipcode && deliveryMethod && isStoreOpen
      : pickupTime && isStoreOpen;

    setIsNFormValid(isPersonalInfoValid && isDeliveryPickupValid);
  };

  useEffect(() => {
    if (paymentIntentId) {
      updatePaymentIntent();
    }
  }, [isDelivery, finalTotal, fee, deliveryFee]);
  useEffect(() => {
    const checkPaymentIntentNeeded = async () => {
      if (
        openAccordion === "payment" &&
        (!currentUser ||
          (currentUser && selectedPaymentMethod === "new") ||
          !customerInfo ||
          !customerInfo.paymentMethods.length ||
          !paymentIntentId)
      ) {
        setNeedsNewPaymentIntent(true);
        const newPaymentIntentId = await createPaymentIntent();
        if (newPaymentIntentId) {
          setPaymentIntentId(newPaymentIntentId);
        }
      }
    };

    checkPaymentIntentNeeded();
  }, [
    openAccordion,
    currentUser,
    selectedPaymentMethod,
    customerInfo,
    finalTotal,
  ]);
  //test this edge
  useEffect(() => {
    const paymentEdge = async () => {
      if (
        openAccordion === "personal-info" ||
        openAccordion === "delivery-pickup"
      ) {
        setNeedsNewPaymentIntent(false);
        await paymentEdge;
      }
    };
  });
  const updatePaymentIntent = async () => {
    if (!paymentIntentId) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/orders/update-payment-intent`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            paymentIntentId,
            amount: finalTotal * 100, // Convert to cents
            fees: fee + deliveryFee,
            orderType: isDelivery ? "delivery" : "pickup",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update payment intent");
      }

      const { updatedClientSecret } = await response.json();
      setClientSecret(updatedClientSecret);
    } catch (error) {
      console.error("Error updating payment intent:", error);
    }
  };
  const createPaymentIntent = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/orders/create-or-update-payment-intent`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            storeId: selectedStore,
            amount: totalCost,
            fees: fee + deliveryFee,
            orderType: isDelivery ? "delivery" : "pickup",
            customerId: currentUser ? currentUser.uid : null,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create payment intent");
      }

      const { clientSecret, paymentIntentId: newPaymentIntentId } =
        await response.json();
      setClientSecret(clientSecret);
      setPaymentIntentId(newPaymentIntentId);
      return newPaymentIntentId;
    } catch (error) {
      console.error("Error creating payment intent:", error);
      // Handle error (e.g., show error message to user)
    }
  };
  useEffect(() => {
    const checkStoreHours = () => {
      if (selectedStore && storePickupTimes[selectedStore]) {
        const availableTimes = storePickupTimes[selectedStore];
        setIsStoreOpen(availableTimes.length > 0);
      } else {
        setIsStoreOpen(false);
      }
    };

    checkStoreHours();
  }, [selectedStore, storePickupTimes]);
  useEffect(() => {
    checkNFormValidity();
  }, [
    firstName,
    lastName,
    email,
    phone,
    isDelivery,
    address,
    city,
    state,
    zipcode,
    deliveryMethod,
    pickupTime,
    isStoreOpen,
  ]);
  useEffect(() => {
    checkFormValidity();
  }, [
    firstName,
    lastName,
    email,
    phone,
    isDelivery,
    address,
    city,
    state,
    zipcode,
    deliveryMethod,
    pickupTime,
    selectedPaymentMethod,
    isPaymentFormValid,
    isStoreOpen,
    isNewCardValid,
  ]);
  const updateCost = (isDelivery) => {
    if (isDelivery) {
      setDeliveryFee(5); // or whatever your standard delivery fee is
    } else {
      setDeliveryFee(0);
    }

    // Update total cost considering the delivery fee and original product cost
    const newTotalCost = originalCost;
    setTotalCost(newTotalCost);
  };

  //firebase
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        fetchCustomerInfo(user);
      } else {
        clearForm();
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchCustomerInfo = async (user) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/customers/info`,
        {
          uid: user.uid,
        }
      );
      const info = response.data;
      setCustomerInfo(info);
      setFirstName(info.firstName);
      setLastName(info.lastName);
      setEmail(info.email);
      setPhone(info.phoneNumber);
      if (info.addresses.length > 0) {
        const defaultAddress =
          info.addresses.find((addr) => addr.isChosen) || info.addresses[0];
        setAddress(defaultAddress.street);
        setCity(defaultAddress.city);
        setState(defaultAddress.state);
        setZipcode(defaultAddress.zipCode);
      }
    } catch (error) {
      console.error("Error fetching customer info:", error);
    }
  };

  const clearForm = () => {
    setCustomerInfo(null);
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setCity("");
    setState("");
    setZipcode("");
    setSelectedPaymentMethod("");
  };

  const calculateTotalCost = (products) => {
    const total = products.reduce(
      (sum, product) =>
        sum + (product.salesPrice || product.price) * product.quantity,
      0
    );
    const originalTotal = products.reduce(
      (sum, product) => sum + product.price * product.quantity,
      0
    );
    console.log(originalTotal);
    console.log(total);
    setTotalCost(total);
    return { total, originalTotal };
  };

  const createPaymentIntentForSavedCard = async (paymentMethodId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/orders/create-payment-intent-with-saved-method`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            storeId: selectedStore,
            amount: totalCost,
            fees: fee + deliveryFee,
            customerId: currentUser.uid,
            paymentMethodId, // Saved card
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create payment intent for saved card");
      }

      const { clientSecret, paymentIntentId } = await response.json();
      setClientSecret(clientSecret);
      setPaymentIntentId(paymentIntentId);
      return { success: true, paymentIntentId };
    } catch (error) {
      console.error("Error creating payment intent:", error);
      return { success: false, message: error.message };
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    try {
      const aggregatedNotes = aggregateNotes();
      const currentUser = auth.currentUser;
      const isLoggedIn = !!currentUser;
      let paymentResult;

      if (currentUser && selectedPaymentMethod !== "new") {
        // Case: Saved payment method
        paymentResult = await createPaymentIntentForSavedCard(
          selectedPaymentMethod
        );
      } else {
        // Case: New payment method
        paymentResult = await handlePaymentSubmit();
      }

      if (!paymentResult.success) {
        throw new Error(paymentResult.message || "Payment failed");
      }

      const orderData = {
        products: products.map((product) => ({
          product: product.id,
          quantity: product.quantity,
        })),
        user: {
          firstName,
          lastName,
          email,
          phoneNumber: phone,
          customerId: isLoggedIn ? currentUser.uid : "a1",
        },
        deliveryFee,
        processingFee: fee,
        totalCost,
        notes: aggregatedNotes,
        storeId: selectedStore,
        discountCode,
        discountPercentage,
        orderType: isDelivery ? "delivery" : "pickup",
        ...(isDelivery
          ? {
              deliveryInfo: {
                address,
                city,
                state,
                zipcode,
                deliveryTime,
              },
            }
          : { pickupTime }),
        payment_intent_id: paymentResult.paymentIntentId, // Use paymentIntentId from paymentResult
      };

      // Create order
      const orderResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/orders`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(orderData),
        }
      );

      if (!orderResponse.ok) {
        throw new Error("Failed to create order");
      }

      const { orderId } = await orderResponse.json();

      // Navigate to success page
      navigate("/order-success", { state: { orderId } });
    } catch (error) {
      console.error("Error processing order:", error.message);
      alert("Failed to process order: " + error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePaymentSubmit = async () => {
    return new Promise((resolve) => {
      if (checkoutFormRef.current) {
        checkoutFormRef.current.handlePayment().then((result) => {
          console.log("Payment result from handlePayment:", result);
          if (result.success) {
            resolve({
              success: true,
              clientSecret: result.clientSecret,
              paymentIntentId: result.paymentIntentId,
            });
          } else {
            resolve({ success: false, message: result.message });
          }
        });
      } else {
        resolve({ success: false, message: "Payment form not initialized" });
      }
    });
  };

  useEffect(() => {
    updateCost(isDelivery);
  }, [isDelivery]);

  const checkoutFormRef = React.useRef();
  //for getting product data
  useEffect(() => {
    const fetchProductDetails = async () => {
      const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
      if (cartItems.length === 0) {
        navigate("/checkout");
        return;
      }

      const productIds = cartItems.map((item) => item.id);
      const quantities = cartItems.reduce((acc, item) => {
        acc[item.id] = item.quantity;
        return acc;
      }, {});

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/products/details`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ productIds }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch product details");
        }

        const data = await response.json();
        const detailedProducts = data.productDetails.map((product) => ({
          ...product,
          price: product.salePrice || product.price, // Use salePrice if available
          quantity: quantities[product.id],
        }));

        setProducts(detailedProducts);

        const storeIds = Array.from(
          new Set(detailedProducts.map((product) => product.storeId))
        );
        if (storeIds.length > 0) {
          setSelectedStore(storeIds[0]);
        }

        const storePickupTimePromises = storeIds.map(async (storeId) => {
          try {
            const pickupResponse = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/stores/clientpickuptimings`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ storeId }),
              }
            );
            if (pickupResponse.ok) {
              const data = await pickupResponse.json();
              console.log(`Pickup time range for store ${storeId}:`, data);

              if (
                data &&
                data.pickupTimeRange &&
                data.pickupTimeRange.start &&
                data.pickupTimeRange.end
              ) {
                return {
                  [storeId]: generateTimeSlots(
                    data.pickupTimeRange.start,
                    data.pickupTimeRange.end
                  ),
                };
              } else {
                console.error(
                  `Invalid pickup time range structure for store ${storeId}. Data:`,
                  data
                );
                return { [storeId]: [] };
              }
            } else {
              console.error(
                `Failed to fetch pickup time for store ${storeId}. Status: ${pickupResponse.status}`
              );
              return { [storeId]: [] };
            }
          } catch (error) {
            console.error(
              `Error fetching pickup time for store ${storeId}:`,
              error
            );
            return { [storeId]: [] };
          }
        });

        const pickupTimesResults = await Promise.all(storePickupTimePromises);
        const pickupTimes = Object.assign({}, ...pickupTimesResults);
        console.log("pickup", pickupTimes);
        setStorePickupTimes(pickupTimes);

        const { total, originalTotal } = calculateTotalCost(detailedProducts);
        setTotalCost(total);
        setOriginalCost(originalTotal);
        if (storeIds.length > 1) {
          setSelectedStore(storeIds[0]);
        } else {
          setSelectedStore(storeIds[0]);
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [navigate]);

  useEffect(() => {
    const newTotal = fee + deliveryFee + totalCost;
    setFinalTotal(newTotal);
  }, [fee, deliveryFee, totalCost]);

  //notes
  const handleAddNote = (productTitle, note) => {
    setNotes((prevNotes) => ({
      ...prevNotes,
      [productTitle]: note,
    }));
  };

  const handleSaveNote = () => {
    if (currentProduct !== null) {
      handleAddNote(currentProduct, tempNote);
      setCurrentProduct(null);
      setTempNote("");
    }
  };

  // New function to aggregate notes into a single string
  const aggregateNotes = () => {
    return Object.entries(notes)
      .map(([title, value]) => `${title}: ${value}`)
      .join(", ");
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <form>
      <div className="App">
        <div className="bg-white p-4 dark:bg-gray-950 ">
          <h3 className="font-bold text-xl md:text-2xl">Checkout</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Swift and Secure
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto py-12 px-4">
          <div className="space-y-6">
            {!showPaymentSection ? (
              <div>
                <div className="space-y-8">
                  <section>
                    <h2 className="text-2xl font-bold mb-4 text-start">
                      Personal Information
                    </h2>
                    <div className="grid gap-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-1">
                          <Label htmlFor="firstName">First Name</Label>
                          <Input
                            id="firstName"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First Name"
                            required
                          />
                        </div>
                        <div className="space-y-1">
                          <Label htmlFor="lastName">Last Name</Label>
                          <Input
                            id="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="space-y-1">
                        <Label htmlFor="email">Email</Label>
                        <Input
                          id="email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          required
                        />
                      </div>
                      <div className="space-y-1">
                        <Label htmlFor="phone">Phone</Label>
                        <Input
                          id="phone"
                          type="tel"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Phone"
                          required
                        />
                      </div>
                    </div>
                  </section>

                  <section>
                    <h2 className="text-2xl font-bold mb-4 text-start">
                      Delivery/Pickup
                    </h2>
                    <div className="grid gap-4">
                      <div className="flex items-center gap-2">
                        <RadioGroup
                          id="delivery"
                          value={isDelivery ? "delivery" : "pickup"}
                          onValueChange={(value) => {
                            const newIsDelivery = value === "delivery";
                            setIsDelivery(newIsDelivery);
                            updateCost(newIsDelivery);
                          }}
                          className="flex items-center gap-2"
                        >
                          <Label
                            htmlFor="delivery-pickup"
                            className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-muted"
                          >
                            <RadioGroupItem
                              id="delivery-pickup"
                              value="pickup"
                              checked={!isDelivery}
                            />
                            Pickup
                          </Label>
                          <Label
                            htmlFor="delivery-delivery"
                            className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-muted"
                          >
                            <RadioGroupItem
                              id="delivery-delivery"
                              value="delivery"
                              checked={isDelivery}
                            />
                            Delivery
                          </Label>
                        </RadioGroup>
                      </div>

                      {isDelivery ? (
                        <div className="space-y-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-1">
                              <Label htmlFor="address">Address</Label>
                              <Input
                                id="address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder="123 Road Dr"
                                required
                              />
                            </div>
                            <div className="space-y-1">
                              <Label htmlFor="city">City</Label>
                              <Input
                                id="city"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                placeholder="Lincoln"
                                required
                              />
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-1">
                              <Label htmlFor="state">State</Label>
                              <Input
                                id="state"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                placeholder="Michigan"
                                required
                              />
                            </div>
                            <div className="space-y-1">
                              <Label htmlFor="zipcode">Zipcode</Label>
                              <Input
                                id="zipcode"
                                value={zipcode}
                                onChange={(e) => setZipcode(e.target.value)}
                                placeholder="12345"
                                required
                              />
                            </div>
                          </div>
                          <div>
                            <Label htmlFor="delivery">Delivery Method</Label>
                            {selectedStore &&
                            storePickupTimes[selectedStore] &&
                            storePickupTimes[selectedStore].length > 0 ? (
                              <div
                                className={`card p-4 border rounded-lg cursor-pointer hover:shadow-lg grid grid-cols-3 items-center ${
                                  deliveryMethod === "standard" ||
                                  deliveryMethod === "6PM"
                                    ? "border-black"
                                    : ""
                                }`}
                                onClick={() => {
                                  setDeliveryMethod("standard");
                                  setDeliveryFee(0);
                                }}
                              >
                                <div className="col-span-2 text-start">
                                  <h3 className="text-lg font-semibold">
                                    Standard
                                  </h3>
                                  <p className="text-sm text-gray-500">
                                    Delivery at 6PM
                                  </p>
                                </div>
                                <div className="col-span-1 text-right">
                                  <p className="text-xl font-bold line-through text-gray-500">
                                    $10
                                  </p>
                                  <p className="text-xl font-bold text-blue-500">
                                    FREE
                                  </p>
                                  <p className="text-sm text-red-600">
                                    Expires in: {timeLeft}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <p>
                                No delivery slots available. This store will
                                open at{" "}
                                {/* {selectedStore && storeOpenTimes[selectedStore]} */}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Label htmlFor="pickupTime">Pickup Time</Label>
                          {selectedStore &&
                          storePickupTimes[selectedStore] &&
                          storePickupTimes[selectedStore].length > 0 ? (
                            <Select
                              id="pickupTime"
                              onValueChange={(value) => setPickupTime(value)}
                              value={pickupTime}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select a time" />
                              </SelectTrigger>
                              <SelectContent>
                                {storePickupTimes[selectedStore].map(
                                  (time, index) => (
                                    <SelectItem key={index} value={time}>
                                      {time}
                                    </SelectItem>
                                  )
                                )}
                              </SelectContent>
                            </Select>
                          ) : (
                            <p>
                              No time slots available. This store will open at{" "}
                              {/* {selectedStore && storeOpenTimes[selectedStore]} */}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </section>
                </div>
                <div className="flex justify-center m-3">
                  <Button
                    onClick={handleContinue}
                    className="group flex items-center space-x-2"
                    disabled={!isNFormValid}
                    type="button" // Add this to ensure it doesn't submit a form
                  >
                    Continue to Payment
                    <span className="transition-transform duration-300 ease-in-out transform group-hover:translate-x-1">
                      <ChevronRight />
                    </span>
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div className="m-2">
                  <h2 className="text-2xl font-bold text-start">
                    Payment Information
                  </h2>
                  {currentUser &&
                  customerInfo &&
                  customerInfo.paymentMethods.length >= 0 ? (
                    <div>
                      <Label htmlFor="paymentMethod">
                        Saved Payment Methods
                      </Label>
                      <Select
                        id="paymentMethod"
                        onValueChange={(value) => {
                          setSelectedPaymentMethod(value);
                          if (value !== "new") {
                            setIsNewCardValid(true);
                          } else {
                            setIsNewCardValid(false);
                          }
                        }}
                        value={selectedPaymentMethod}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select a payment method" />
                        </SelectTrigger>
                        <SelectContent>
                          {customerInfo.paymentMethods.map((pm) => (
                            <SelectItem key={pm.id} value={pm.id}>
                              {pm.brand.toUpperCase()} ending in {pm.last4}
                            </SelectItem>
                          ))}
                          {/* <SelectItem value="new">Add a new card</SelectItem> */}
                        </SelectContent>
                      </Select>
                      {customerInfo.paymentMethods.length <= 1 ? (
                        <div className="text-red-500">Please add a method</div>
                      ) : null}
                      <div className="mt-4">
                        <Button
                          onClick={(event) => {
                            event.preventDefault(); // Prevent the default form behavior
                            window.location.href = "/cu/addpaymentmethod"; // Navigate to add payment
                          }}
                          className="w-full"
                        >
                          Add new payment method
                        </Button>
                      </div>{" "}
                    </div>
                  ) : (
                    <Elements options={{ clientSecret }} stripe={stripePromise}>
                      <CheckoutForm
                        ref={checkoutFormRef}
                        // onPaymentResult={(success, message) => {
                        //   if (!success) {
                        //     alert(message || "Payment failed");
                        //   }
                        // }}
                        onValidityChange={(isValid) => {
                          setIsPaymentFormValid(isValid);
                          setIsNewCardValid(isValid);
                        }}
                      />
                    </Elements>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* order details non personal */}
          <div className="space-y-6">
            <div className="border rounded-lg overflow-hidden">
              <div className="bg-muted p-4 font-medium">Your Order</div>
              <div className="space-y-4 p-4">
                <div className="flex justify-between mb-2">
                  <div className="font-medium">Products</div>
                  <div className="font-medium">Add a note</div>
                </div>
                <Separator />
                {products.map((product) => (
                  <div
                    key={product.id}
                    className="flex justify-between items-start"
                  >
                    <div className="flex items-start">
                      {" "}
                      {/* Main container with horizontal spacing */}
                      <img
                        src={product.imageUrls[0]}
                        className="w-24 h-24 object-cover flex-shrink-0"
                        alt="Product Image"
                      />{" "}
                      {/* Adjust image size as needed */}
                      <div className="flex-grow">
                        <div className="font-medium text-start">
                          {product.title}
                        </div>
                        {product.salePrice ? (
                          <div className="flex items-center text-start">
                            {/* Sale price */}
                            <div className="text-blue-500 font-bold">
                              $
                              {(product.salePrice * product.quantity).toFixed(
                                2
                              )}
                            </div>
                            {/* Original price crossed out */}
                            <div className="text-gray-500 line-through ml-2">
                              ${(product.price * product.quantity).toFixed(2)}
                            </div>
                            <div className="text-gray-500  ml-2">
                              x {product.quantity}
                            </div>
                          </div>
                        ) : (
                          <div className="text-gray-500 text-start">
                            ${product.price} x {product.quantity}
                          </div>
                        )}
                        {notes[product.title] && (
                          <div className="text-blue-500 text-sm mt-1 text-start">
                            Note: {notes[product.title]}
                          </div>
                        )}
                      </div>
                    </div>
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => {
                            setCurrentProduct(product.title);
                            setTempNote(notes[product.title] || "");
                          }}
                        >
                          <Pen className="w-4 h-4" />
                          <span className="sr-only">Add note</span>
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="sm:max-w-[425px]">
                        <DialogHeader>
                          <DialogTitle>Add Note</DialogTitle>
                          <DialogDescription>
                            Add a note for {product.title}.
                          </DialogDescription>
                        </DialogHeader>
                        <div className="grid gap-4 py-4">
                          <Textarea
                            placeholder="Enter your note..."
                            value={tempNote}
                            onChange={(e) => setTempNote(e.target.value)}
                          />
                        </div>
                        <DialogFooter>
                          <DialogClose>
                            <Button type="submit" onClick={handleSaveNote}>
                              Save Note
                            </Button>
                          </DialogClose>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                  </div>
                ))}
                <Separator />
                <div className="flex justify-between items-center font-medium">
                  <div>Subtotal</div>
                  <div>
                    $
                    {Number.isFinite(totalCost) ? totalCost.toFixed(2) : "0.00"}
                  </div>
                </div>
                {isDelivery && (
                  <div className="flex justify-between items-center font-medium">
                    <div>Delivery Fee</div>
                    <div>
                      $
                      {Number.isFinite(deliveryFee)
                        ? deliveryFee.toFixed(2)
                        : "0.00"}
                    </div>
                  </div>
                )}
                <div className="flex justify-between items-center font-medium">
                  <div>Processing Fee</div>
                  <div>${Number.isFinite(fee) ? fee.toFixed(2) : "0.00"}</div>
                </div>
                <div className="flex justify-between items-center font-medium">
                  <div>Tax</div>
                  <div>
                    $
                    {Number.isFinite(taxAmount) ? taxAmount.toFixed(2) : "0.00"}
                  </div>
                </div>
                <Separator />
                <div className="flex justify-between items-center font-medium text-xl">
                  <div>Total</div>
                  <div>
                    $
                    {Number.isFinite(finalTotal)
                      ? finalTotal.toFixed(2)
                      : "0.00"}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center">
              <Button
                size="lg"
                type="submit"
                className="flex items-center"
                onClick={handleSubmit}
                disabled={
                  isProcessing || !isFormValid || (isDelivery && !isStoreOpen)
                }
              >
                <span className="mr-2">
                  {isDelivery ? <Truck size={24} /> : <Store size={24} />}
                </span>
                {isProcessing ? "Processing..." : "Place Order and Pay"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PlaceOrder;
